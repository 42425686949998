<template>
    <div>
        <div v-if="home" class="warp">
            <div class="header">
                <div data-v-e440e168="" class="van-nav-bar van-hairline--bottom van-nav-bar--fixed">
                    <div class="van-nav-bar__content">
                        <div class="van-nav-bar__title van-ellipsis" @click="showDirection"> {{ obj.title }}
                            <i class="van-icon question_back_choose"></i>
                        </div>
                    </div>
                </div>
            </div>

            <div class="bodyWarp">
                <div class="chartContent">
                    <div class="chartTitle">
                        <span class="manage">{{ obj.manage }}</span>
                    </div>

                    <div class="chartWarp">
                        <van-row>
                            <van-col span="6">
                                <div ref="chart1" class="chart chart1"></div>
                                <span class="title">答题数</span>
                            </van-col>
                            <van-col span="6">
                                <div ref="chart2" class="chart chart2"></div>
                                <span class="title">错题数</span>
                            </van-col>
                            <van-col span="6">
                                <div ref="chart3" class="chart chart3"></div>
                                <span class="title">正确率</span>
                            </van-col>
                            <van-col span="6">
                                <div ref="chart4" class="chart chart4"></div>
                                <span class="title">总题数</span>
                            </van-col>
                        </van-row>
                    </div>

                    <div class="duration">
                        <img class="icon" src="@/assets/all_Icon/drawable-xxhdpi/question_back_time.png" />
                        <span>累计做题时长为{{ duration }}</span>
                    </div>
                </div>

                <div class="questionType">
                    <van-row>
                        <van-col span="6" class="content" v-for="(item,index) in list" :key="index" @click="goTo('questionType',item)">
                            <img class="img" :src="item.src" />
                            <div class="title">{{ item.name }}</div>
                        </van-col>
                    </van-row>
                </div>

                <!-- <div class="rankingList" @click="goTo('ranking')">
                    <div class="Top" v-for="(item,index) in ranking" :key="item.userId" :class="'top'+(index+1)">
                        <div class="img">
                            <img :src="item.userHead" />
                        </div>
                        <div>
                            <p class="tel">{{ item.userName }}</p>
                            <p class="topic">{{ item.questionsNumber }}道题</p>
                        </div>
                    </div>
                </div> -->
            </div>
            <!-- 底部导航 -->
            <tabBar></tabBar>
        </div>
        <directionTab v-else @showHome="showHome"></directionTab>
    </div>
</template>

<script>
import { getQuestionBank, } from "@/api/questionBank.js";
import tabBar from '@/components/tabBar' //底部导航 组件
import directionTab from '@/pages/study/studyDirection/directionTab' //底部导航 组件
import { coures } from '@/api/study_course.js'


export default {
    components: { tabBar, directionTab },
    data() {
        return {
            list: [
                {
                    name: '章节练习',
                    href: '',
                    src: require('@/assets/all_Icon/drawable-xxhdpi/question_back_zjlx.png'),
                },
/*                {
                    name: '专项练习',
                    href: '',
                    src: require('@/assets/all_Icon/drawable-xxhdpi/question_back_zxlx.png')
                },*/
                {
                    name: '历年真题',
                    href: '',
                    src: require('@/assets/all_Icon/drawable-xxhdpi/question_back_lnzt.png')
                },
                {
                    name: '模拟试卷',
                    href: '',
                    src: require('@/assets/all_Icon/drawable-xxhdpi/question_back_mnsj.png')
                },
                {
                    name: '考前押题',
                    href: '',
                    src: require('@/assets/all_Icon/drawable-xxhdpi/question_back_kqyt.png')
                },
                {
                    name: '错题本',
                    href: '',
                    src: require('@/assets/all_Icon/drawable-xxhdpi/question_back_ctb.png')
                },
                {
                    name: '习题收藏',
                    href: '',
                    src: require('@/assets/all_Icon/drawable-xxhdpi/question_back_xtsc.png')
                },
                {
                    name: '做题记录',
                    href: '',
                    src: require('@/assets/all_Icon/drawable-xxhdpi/question_back_ztjl.png')
                },
                {
                    name: '排行榜',
                    href: '',
                    src: require('@/assets/all_Icon/drawable-xxhdpi/question_back_lnzt.png')
                }
            ],
            obj: {
                title: '',
                manage: ''
            },
            duration: '',
            ranking: [],
            secondId: '',
            home: false
        }
    },
    name: 'questionBank',
    methods: {
        getQuestionBank() {
            getQuestionBank(this.secondId).then((res) => {
                this.$toast.clear();
                if (res.data.code === 0 && res.data.data) {
                    let data = res.data.data;
                    this.duration = data.sumDuration;
                    this.obj.title = data.firstName;
                    this.obj.manage = data.secondName;
                    this.ranking = data.rankingList;

                    this.getCharts({
                        el: '.chart1',
                        // text: data.answerPaperCount,
                        text: data.answerNumber,
                        // data: [{ value: data.answerNumber }, { value: data.countNumber - data.answerPaperCount }]
                        data: [{ value: data.answerNumber }, { value: data.countNumber - data.answerNumber }]
                    });
                    this.getCharts({
                        el: '.chart2',
                        text: data.errorAnswerNumber,
                        // data: [{ value: data.errorAnswerNumber }, { value: data.countNumber - data.errorAnswerNumber }]
                        data: [{ value: data.errorAnswerNumber }, { value: data.countNumber - data.errorAnswerNumber }]
                    });
                    this.getCharts({
                        el: '.chart3',
                        text: data.accuracy,
                        data: [{ value: data.accuracy }, { value: 100 - data.accuracy }]
                    });
                    this.getCharts({
                        el: '.chart4',
                        text: data.countNumber,
                        data: [{ value: data.countNumber }, { value: data.countNumber - data.countNumber }]
                    });
                }
            })
        },
        goTo(type, item) {
            if (type === 'questionType') {
                switch (item.name) {
                    case '历年真题':
                        this.$router.push({
                            path:'chapter',
                            query:{
                                id:this.$store.getters.getStudyList.secondId
                            }
                        });
                        break;
                    case '模拟试卷':
                        this.$router.push({
                            path:'simulated_paper',
                            query:{
                                id:this.$store.getters.getStudyList.secondId
                            }
                        });
                        break;
                    case '考前押题':
                        this.$router.push({
                            path:'examination_questions',
                            query:{
                                id:this.$store.getters.getStudyList.secondId
                            }
                        });
                        break;
                    case '错题本':
                        this.$router.push({
                            path:'wrongquestionbook',
                            query:{
                                id:this.$store.getters.getStudyList.secondId
                            }
                        });
                        break;
                    case '章节练习':
                        this.$router.push({
                            path:'chapterExercises',
                            query:{
                                id:this.$store.getters.getStudyList.secondId
                            }
                        });
                        break;
                    case '做题记录':
                        this.$router.push({
                            path:'questionRecord',
                            query:{
                                id:this.$store.getters.getStudyList.secondId
                            }
                        });
                        break;
                    case '习题收藏':
                        this.$router.push('collection');
                        break;
                    case '专项练习':
                        this.$toast('萨恩课堂：暂未开放');
                        break;
                    case '排行榜':
                        this.$router.push('rankingList');
                        break;    
                }

            } else if (type === 'ranking') {
                // 排行榜
                this.$router.push('rankingList');
            }
        },
        getCharts(opt) {
            this.$nextTick(() => {
                let chart = this.$echarts.init(document.querySelectorAll(opt.el)[0]);
                let option = {
                    title: {
                        text: opt.text,  //图形标题，配置在中间对应效果图的80%
                        left: "center",
                        top: "35%",
                        textStyle: {
                            color: "#6483FF",
                            fontSize: 14,
                            align: "center"
                        }
                    },
                    series: [
                        {
                            type: 'pie',
                            radius: ['80%', '90%'],  //设置内外环半径,两者差值越大，环越粗
                            color: ['#6483FF', '#F2F5FF'],
                            label: {
                                show: false,
                                position: 'center'
                            },
                            data: opt.data
                        }
                    ]
                };
                chart.setOption(option);
            })
        },
        showDirection() {
            this.$toast.clear();
            // this.$router.push('directionTab');
            this.home = false;
        },
        showHome() {
            this.home = true;
            this.secondId = window.localStorage.getItem('secondId');
            this.getQuestionBank();
        }
    },
    async created() {
        let res = await coures()
        // if(res.data.code == -1) return 


        this.secondId = window.localStorage.getItem('secondId');
        this.$toast.loading({
            message: '加载中...',
            forbidClick: true,
            loadingType: 'spinner'
        });
        if (this.secondId) {
            this.showHome();
        } else {
            this.home = false;
            this.$toast.clear();
            // this.showDirection();
        }
    }
}
</script>

<style lang="less" scoped>
.header {
    font-size: 17px;
    font-weight: 500;
    color: #141414;
    line-height: 24px;
}

/deep/ .van-nav-bar__title {
    font-size: 17px;
    font-weight: 500;
    color: #141414;

    .van-icon {
        //transform: rotate(90deg);
        color: #333333;
    }

    .question_back_choose {
        width: 17px;
        height: 15px;
        background: url("../../assets/all_Icon/drawable-xxhdpi/question_back_choose.png")
            no-repeat 100% 100%/100%;
    }
}

/deep/ .header .van-icon-arrow-left {
    color: #000;
}

.bodyWarp {
    margin-top: 42px;
    background: #f8f8f8;
    height: calc(100vh - 78px);
    overflow-y: auto;

    .chartContent {
        background: #fff;
        padding: 0 15px;
        margin-top: 7px;

        .chartTitle {
            height: 24px;
            line-height: 24px;
            padding-top: 22px;

            span {
                display: inline-block;
            }

            .manage {
                font-size: 17px;
                font-weight: 500;
                color: #3a3a3a;
            }

            .type {
                height: 20px;
                font-size: 14px;
                font-weight: 400;
                color: #717171;
                line-height: 20px;
                margin-left: 15px;
            }
        }

        .chartWarp {
            margin-top: 10px;
            text-align: center;

            .chart {
                width: 58px;
                height: 58px;
                margin: 0 auto;
            }

            .title {
                display: inline-block;
                margin-top: 10px;
                height: 18px;
                font-size: 13px;
                font-weight: 400;
                color: #717171;
                line-height: 18px;
            }
        }

        .duration {
            padding: 16px 0;

            .icon {
                display: inline-block;
                width: 12px;
                height: 14px;
                //background: #FFAD64;
                margin-right: 9px;
            }

            span {
                height: 18px;
                font-size: 13px;
                font-weight: 400;
                color: #666666;
                line-height: 18px;
            }
        }
    }

    .questionType {
        margin-top: 7px;
        text-align: center;
        background: #fff;
        padding: 0 15px 22px;

        .content {
            margin-top: 22px;

            img {
                width: 24px;
                height: 29px;
            }

            .title {
                height: 18px;
                font-size: 13px;
                font-weight: 400;
                color: #3a3a3a;
                line-height: 18px;
            }
        }
    }

    .rankingList {
        margin-top: 7px;
        background: #fff;
        height: 250px;
        position: relative;

        .Top {
            position: absolute;
            text-align: center;

            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }

            .tel {
                margin-top: 27px;
                height: 15px;
                font-size: 11px;
                font-weight: 400;
                color: #636363;
                line-height: 15px;
            }

            .topic {
                margin-top: 3px;
                height: 23px;
                font-size: 16px;
                font-weight: 500;
                color: #636363;
                line-height: 23px;
            }
        }

        .top1 {
            top: 27px;
            left: 155px;

            .img {
                width: 71px;
                height: 71px;
                position: relative;
                background: url("../../assets/all_Icon/drawable-xxhdpi/first_bg.png")
                    no-repeat 50% 50%/90%;

                img {
                    width: 51px;
                    height: 51px;
                    position: absolute;
                    left: 7px;
                    bottom: 3px;
                }
            }
        }

        .top2 {
            top: 56px;
            left: 59px;

            .img {
                width: 61px;
                height: 61px;
                position: relative;
                background: url("../../assets/all_Icon/drawable-xxhdpi/second_bg.png")
                    no-repeat 50% 50%/90%;

                img {
                    width: 41px;
                    height: 41px;
                    position: absolute;
                    left: 7px;
                    bottom: 3px;
                }
            }
        }

        .top3 {
            top: 80px;
            left: 269px;

            .img {
                width: 51px;
                height: 51px;
                position: relative;
                background: url("../../assets/all_Icon/drawable-xxhdpi/third_bg.png")
                    no-repeat 50% 50%/90%;

                img {
                    width: 31px;
                    height: 31px;
                    position: absolute;
                    left: 7px;
                    bottom: 3px;
                }
            }
        }
    }
}
</style>
